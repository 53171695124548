export const IS_LOCAL_DEV_MODE = window.location.hostname === "localhost";
const isFromRustici = () => {
  try {
    if (IS_LOCAL_DEV_MODE) return true;
    const parentHostname = window.parent.location.hostname;
    const parentPathname = window.parent.location.pathname;
    const isAllowPath = parentHostname.includes("deephow");
    return isAllowPath && parentPathname === "/static/contentAPI.html";
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const IS_FROM_RUSTICI = isFromRustici();

export const countDuration = (steps, countKey) => steps.reduce((result, step) => result + step[countKey], 0);

const getRXD = () => {
  const RXD = window.parent.RXD;

  if (RXD) {
    return RXD;
  }
  throw "RXD is undefined";
};

export const getBookmark = () => {
  try {
    const RXD = getRXD();
    const bookmarkContent = RXD.GetBookmark();
    return bookmarkContent;
  } catch (e) {
    return "";
  }
};

export const getLessonStatus = () => {
  try {
    const RXD = getRXD();
    return RXD.GetStatus();
  } catch (e) {
    return "";
  }
};

export const getRusticiToken = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return `${searchParams.get("d")}.${searchParams.get("h")}.${searchParams.get("t")}`;
};

export const getStudentInfo = () => {
  if (IS_LOCAL_DEV_MODE) {
    return {
      studentId: "korver.huang@deephow.com",
      accountId: 13,
      displayName: "Korver Huang",
    };
  }

  try {
    const RXD = getRXD();
    const studentId = RXD.GetStudentID();
    const displayName = RXD.GetStudentName();
    const accountId = new URLSearchParams(window.parent.location.search).get("accountId");
    return { accountId, studentId, displayName };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getSuspendData = () => {
  try {
    const RXD = getRXD();
    const suspendData = RXD.GetSuspendData();
    return suspendData;
  } catch (e) {
    return "";
  }
};

export const setBookmark = (bookmarkContent) => {
  try {
    const RXD = getRXD();
    RXD.SetBookmark(bookmarkContent);
  } catch (e) {}
};

export const setReachedEnd = (bookmarkContent) => {
  try {
    const RXD = getRXD();
    RXD.SetReachedEnd(bookmarkContent);
  } catch (e) {}
};

export const setSuspendData = (suspendData) => {
  try {
    const RXD = getRXD();
    RXD.SetSuspendData(suspendData);
  } catch (e) {}
};

export const setTakeTheQuizResult = (score) => {
  try {
    const RXD = getRXD();
    RXD.ResetStatus();
    if (score === 100) {
      RXD.SetPassed();
    } else {
      RXD.SetFailed();
    }
    RXD.SetScore(score, 100, 0);
  } catch (e) {
    console.log(e);
  }
};

let rusticiInfo = {};
export const setRusticiInfo = ({ studentId, type }) => {
  /*
   * Check URL is a valid `pipe_url`.
   * Example: https://deephow-demo.contentcontroller.com/vault/{value1}/courses/{value2}/0/rxd
   */
  const url = window.parent?.location?.href;
  const pipeUrl = url ? new URLSearchParams(new URL(url).search).get("pipeurl") : null;
  rusticiInfo = {
    student_id: studentId,
    ...(type && { type }),
    ...(pipeUrl && { pipe_url: decodeURIComponent(pipeUrl) }),
  };
};

export const getRusticiInfo = () => rusticiInfo;
